/* 边框默认为0px */
* {
	margin: 0px;
	padding: 0px;
}

body,html{
	height:100%;
	font-family:"宋体";
}

/* 整个页面 */
#page{
	clear: both;
	height: inherit;
}

#view_loading{
	/* filter: opacity(0.1); */
	background-color: rgba(0, 0, 0, 0.1);;
	position: absolute;
	z-index:9;
}

/* #icon_loading{
	align-self: center;
} */


/* 左边空白区域 */
#left_blank {
	float: left;
	height: inherit;
}

/* 中间内容区域 */
#content {
	float: left;
	height: inherit;
	background-color: lightblue;
}

/* 右边空白区域 */
#right_blank {
	float: left;
	height: inherit;
}


.horizontalCenter {
	display: flex;
	justify-content: center;
}


.verticalCenter {
	display: flex;
	align-items:center;
}

.space_around {
	display: flex;
	justify-content:space-around;
}


.logo {
	width: 200px;
	height: 100%;
	float: left;
	font-size: 2em;
}

.site-layout-background {
	background: white;
}


.hwWhiteColor {
	color: white;
}

.hwBlackColor {
	color: black;
}

.hwRedColor {
	color: orangered;
}

.hwBlueColor{
	color: dodgerblue;
}


.hwWhiteBackground {
	background: white;
}

.hwGreenBackground {
	background: forestgreen;
}

.hwBlueBackground{
	background: dodgerblue;
}


.HWOverflowYAuto{
	overflow-y:auto;
}

.HWEllipase {
	word-break: break-all;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}
